import React, { useEffect, useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { analytics, logEvent } from './firebase';
import Header from './components/Header';
import Footer from './components/Footer';
import Locations from './components/Locations';
import Policies from './components/Policies';
import NotFound from './components/NotFound';
import Shop from './components/Shop';
import FAQ from './components/FAQ';
import EventPage from './components/EventPage';
import ProductPage from './components/ProductPage';
import Cart from './components/Cart';
import { CartProvider, CartContext } from './contexts/CartContext';
import ThankYou from './components/ThankYou';
import Membership from './components/Membership';
import Contact from './components/Contact';
import About from './components/About';

// Import IBM Plex Mono font
import '@fontsource/ibm-plex-mono/400.css';
import '@fontsource/ibm-plex-mono/700.css';

const CartWrapper = ({ children }) => {
  const location = useLocation();
  const { cartItems } = useContext(CartContext);
  const showCart = cartItems.length > 0 && (location.pathname === '/shop' || location.pathname.startsWith('/shop/'));

  return (
    <>
      {children}
      {showCart && <Cart />}
    </>
  );
};

const AppContent = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_title: document.title
    });
  }, [location.pathname]);

  return (
    <div className="flex flex-col min-h-screen font-mono bg-white text-black">
      <Header />
      <CartWrapper>
        <main className="flex-grow flex flex-col">
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<Locations />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/shop/:handle" element={<ProductPage />} />
              <Route path="/frequently-asked-questions" element={<FAQ />} />
              <Route path="/event/:eventId" element={<EventPage />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/membership" element={<Membership />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </main>
      </CartWrapper>
      <Footer />
    </div>
  );
};

const App = () => (
  <CartProvider>
    <AppContent />
  </CartProvider>
);

export default App;