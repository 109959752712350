import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { functions, httpsCallable } from '../firebase';

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const getShopifyCollections = httpsCallable(functions, 'getShopifyCollections');
        const result = await getShopifyCollections();
        if (result.data && result.data.products && result.data.products.length > 0) {
          setProducts(result.data.products);
        } else {
          setError('No products available at this time.');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to load products. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  return (
    <motion.div 
      className="w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="sticky top-0 z-10 bg-white px-4 py-1 border-b border-black">
        <h1 className="text-3xl font-bold uppercase">Shop</h1>
      </div>
      <AnimatePresence>
        {isLoading ? (
          <motion.div 
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex items-center justify-center min-h-screen"
          >
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-black"></div>
          </motion.div>
        ) : error ? (
          <motion.div 
            key="error"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex items-center justify-center min-h-screen"
          >
            {error}
          </motion.div>
        ) : (
          <motion.div 
            key="products"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="grid grid-cols-1 md:grid-cols-3 gap-0"
          >
            {products.map((product) => (
              <motion.div 
                key={product.id} 
                className="border border-black hover:bg-gray-100 transition-colors duration-300 flex flex-col md:h-screen md:max-h-[calc(100vh-100px)]"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Link to={`/shop/${product.handle}`} className="flex-grow flex flex-col">
                  <div className="aspect-w-1 aspect-h-1 w-full">
                    {product.images && product.images.length > 0 && (
                      <img
                        src={product.images[0].src}
                        alt={product.title}
                        className="w-full h-full object-contain p-4"
                        loading="lazy"
                      />
                    )}
                  </div>
                  <div className="flex-grow flex flex-col justify-end">
                    <div className="border-t border-black p-4 md:border md:border-black">
                      <h2 className="text-xl mb-2">{product.title}</h2>
                      <p className="text-lg">
                        ${parseFloat(product.variants[0].price.amount).toFixed(2)} {product.variants[0].price.currencyCode}
                      </p>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Shop;
