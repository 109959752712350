import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiX, FiMail } from 'react-icons/fi';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../assets/web-logo.png';
import { analytics, logEvent } from '../firebase';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (itemName) => {
    logEvent(analytics, 'menu_item_click', {
      item_name: itemName
    });
    handleMenuToggle();
  };

  const menuItems = [
    { to: "/", text: "Locations" },
    { to: "/about", text: "About" },
    { to: "/shop", text: "Shop" },
    { to: "/membership", text: "Membership" },
  ];

  return (
    <header className="bg-black text-white py-4">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">
          <img src={logo} className="h-8 w-auto" alt="logo" />
        </Link>
        
        {/* Mobile menu button */}
        <button
          className="text-white focus:outline-none md:hidden"
          onClick={handleMenuToggle}
        >
          {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>

        {/* Desktop centered nav */}
        <nav className="hidden md:flex space-x-8 absolute left-1/2 transform -translate-x-1/2">
          {menuItems.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              className="text-sm uppercase hover:text-gray-300 transition-colors duration-300"
            >
              {item.text}
            </Link>
          ))}
        </nav>

        {/* Contact icon button */}
        <Link
          to="/contact"
          className="hidden md:flex items-center justify-center text-white hover:text-gray-300 transition-colors duration-300"
        >
          <FiMail size={24} />
        </Link>

        {/* Mobile menu */}
        <AnimatePresence>
          {menuOpen && (
            <motion.div
              className="fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-black z-50 flex flex-col"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="flex justify-between items-center p-4 border-b border-gray-800">
                <Link to="/" onClick={handleMenuToggle}>
                  <img src={logo} className="h-8 w-auto" alt="logo" />
                </Link>
                <button
                  className="text-white"
                  onClick={handleMenuToggle}
                >
                  <FiX size={24} />
                </button>
              </div>
              <nav className="flex flex-col">
                {menuItems.map((item, index) => (
                  <div key={index} className="border-b border-gray-800">
                    <Link
                      to={item.to}
                      className="text-2xl py-4 px-4 block hover:bg-gray-900 transition-colors duration-300"
                      onClick={() => handleMenuItemClick(item.text)}
                    >
                      {item.text.toUpperCase()}
                    </Link>
                  </div>
                ))}
                <div className="border-t border-gray-800 mt-auto">
                  <Link
                    to="/contact"
                    className="text-2xl py-4 px-4 block hover:bg-gray-900 transition-colors duration-300"
                    onClick={() => handleMenuItemClick("Contact")}
                  >
                    CONTACT
                  </Link>
                </div>
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Header;