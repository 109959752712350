import React, { useContext, useCallback } from 'react';
import { CartContext } from '../contexts/CartContext';
import { analytics, logEvent } from '../firebase';
import { motion, AnimatePresence } from 'framer-motion';

const Cart = () => {
  const { cartItems, removeFromCart, updateCartItemQuantity, initiateCheckout, isLoading, error, setError, isCartOpen, setIsCartOpen } = useContext(CartContext);

  const handleCheckout = useCallback(async () => {
    try {
      const checkoutUrl = await initiateCheckout();
      
      logEvent(analytics, 'begin_checkout', {
        currency: 'USD',
        value: cartItems.reduce((total, item) => total + parseFloat(item.variant.price?.amount || 0) * item.quantity, 0),
        items: cartItems.map(item => ({
          item_id: item.variant.id,
          item_name: item.product.title,
          item_variant: item.variant.title,
          price: parseFloat(item.variant.price?.amount || 0),
          quantity: item.quantity
        }))
      });
      
      // Ensure the URL is valid before redirecting
      if (checkoutUrl && checkoutUrl.startsWith('http')) {
        window.location.href = checkoutUrl;
      } else {
        throw new Error('Invalid checkout URL');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setError('Failed to initiate checkout. Please try again.');
    }
  }, [cartItems, initiateCheckout, setError]);

  const handleRemoveItem = useCallback(async (item) => {
    try {
      await removeFromCart(item.id);
      logEvent(analytics, 'remove_from_cart', {
        currency: 'USD',
        value: parseFloat(item.variant.price?.amount || 0),
        items: [{
          item_id: item.variant.id,
          item_name: item.product.title,
          item_variant: item.variant.title,
          price: parseFloat(item.variant.price?.amount || 0),
          quantity: 1
        }]
      });
    } catch (error) {
      console.error('Error removing item from cart:', error);
      // You can add a user-friendly error message here if needed
    }
  }, [removeFromCart]);

  const handleQuantityChange = async (item, newQuantity) => {
    if (newQuantity > 0) {
      await updateCartItemQuantity(item.id, newQuantity);
    } else {
      await removeFromCart(item.id);
    }
  };

  return (
    <div className={`fixed bottom-0 left-0 right-0 bg-black text-white transition-all duration-300 ${isCartOpen ? 'h-64' : 'h-16'}`}>
      <div className="container mx-auto px-4 h-full">
        <button 
          className="w-full h-16 text-xl font-bold flex items-center justify-between uppercase"
          onClick={() => setIsCartOpen(!isCartOpen)}
        >
          Cart ({cartItems.reduce((total, item) => total + item.quantity, 0)} items)
          <span>{isCartOpen ? '▼' : '▲'}</span>
        </button>
        {isCartOpen && (
          <div className="overflow-y-auto h-48">
            {error && <p className="text-red-500">{error}</p>}
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                {cartItems.map((item) => (
                  <div key={item.id} className="flex justify-between items-center py-2 border-b border-gray-700">
                    <span className="text-sm">{item.product.title} - {item.variant.title}</span>
                    <div className="flex items-center">
                      <button 
                        onClick={() => handleQuantityChange(item, item.quantity - 1)}
                        className="text-sm px-2 py-1 bg-gray-700 rounded-l"
                      >
                        -
                      </button>
                      <span className="text-sm px-2 py-1 bg-gray-800">{item.quantity}</span>
                      <button 
                        onClick={() => handleQuantityChange(item, item.quantity + 1)}
                        className="text-sm px-2 py-1 bg-gray-700 rounded-r"
                      >
                        +
                      </button>
                      <span className="text-sm mx-2">${((parseFloat(item.variant.price?.amount || 0) * item.quantity).toFixed(2))}</span>
                      <button 
                        onClick={() => handleRemoveItem(item)}
                        className="text-sm text-red-500 hover:text-red-700 uppercase"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                {cartItems.length > 0 && (
                  <button 
                    onClick={handleCheckout}
                    className="mt-4 w-full bg-white text-black px-4 py-2 uppercase hover:bg-gray-200 transition-colors duration-300"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Processing...' : 'Proceed to Checkout'}
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
