import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { db, analytics, logEvent } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Membership = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [openAccordion, setOpenAccordion] = useState(null);

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Membership',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const waitlistRef = collection(db, 'waitlist');
      const newEntry = {
        email: email.toLowerCase(),
        timestamp: new Date()
      };

      const q = query(waitlistRef, where("email", "==", email.toLowerCase()));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setSubmitStatus('existing');
        logEvent(analytics, 'waitlist_signup_attempt', { status: 'existing' });
      } else {
        await addDoc(waitlistRef, newEntry);
        setSubmitStatus('success');
        setEmail('');
        logEvent(analytics, 'waitlist_signup_success', { email: email });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
      logEvent(analytics, 'waitlist_signup_error', { error: error.message });
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderSubmitStatus = () => {
    switch (submitStatus) {
      case 'success':
        return <p className="mt-4 text-green-500">Thank you for joining our waitlist!</p>;
      case 'existing':
        return <p className="mt-4 text-yellow-500">This email is already on our waitlist.</p>;
      case 'error':
        return <p className="mt-4 text-red-500">An error occurred. Please try again later.</p>;
      default:
        return null;
    }
  };

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index);
  };

  const memberBenefits = [
    {
      title: "SKIP THE LINE",
      description: "Priority entry to our events, so you never miss a moment."
    },
    {
      title: "+1S",
      description: "Bring a friend or two, and they'll enjoy the same perks you do."
    },
    {
      title: "EXCLUSIVE INVITATIONS",
      description: "Be the first to know about private gatherings, activations, and product launches."
    },
    {
      title: "FIRST ACCESS",
      description: "We're launching a beta version of our app — and you'll be among the first to use it."
    }
  ];

  const accordionVariants = {
    open: { opacity: 1, height: "auto" },
    collapsed: { opacity: 0, height: 0 }
  };

  const accordionTransition = { duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] };

  return (
    <motion.div 
      className="w-full bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 uppercase">JOIN ______.</h1>
          
          <div className="mb-12 text-lg md:text-xl uppercase">
            <p className="mb-8">
            ANYONE CAN ATTEND OUR EVENTS — BUT MEMBERSHIP IS FOR THOSE WHO KEEP COMING BACK, THE ONES WHO TRULY GET WHAT WE'RE ABOUT. WE CREATED THIS MEMBERSHIP TO ELEVATE THE EXPERIENCE FOR OUR MOST DEDICATED COMMUNITY MEMBERS, OFFERING EXCLUSIVE PERKS AND INSIDER ACCESS.
            </p>
            
            <div className="mb-8">
              <h3 className="text-xl md:text-2xl font-semibold mb-4">HERE'S WHAT YOU GET AS A MEMBER:</h3>
              {memberBenefits.map((benefit, index) => (
                <div key={index} className="mb-2 border border-black overflow-hidden">
                  <button
                    onClick={() => toggleAccordion(index)}
                    className="flex justify-between items-center w-full text-left text-lg md:text-xl font-semibold p-4 focus:outline-none"
                  >
                    <span>{benefit.title}</span>
                    <motion.div
                      initial={false}
                      animate={{ rotate: openAccordion === index ? 180 : 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <FiChevronDown />
                    </motion.div>
                  </button>
                  <AnimatePresence initial={false}>
                    {openAccordion === index && (
                      <motion.div
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={accordionVariants}
                        transition={accordionTransition}
                        className="overflow-hidden"
                      >
                        <div className="p-4 border-t border-black">
                          <p className="uppercase">{benefit.description}</p>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-2 border border-black overflow-hidden">
            <h2 className="text-xl md:text-2xl font-semibold p-4 uppercase">JOIN THE WAITLIST</h2>
            <div className="p-4 border-t border-black">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full p-3 border border-gray-300 text-black focus:outline-none focus:border-black transition-colors uppercase"
                    placeholder="ENTER YOUR EMAIL"
                  />
                </div>
                <button 
                  type="submit" 
                  className="w-full text-lg md:text-xl px-6 py-3 bg-black text-white hover:bg-gray-800 transition-colors duration-300 uppercase"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'JOINING...' : 'JOIN WAITLIST'}
                </button>
              </form>
              {renderSubmitStatus()}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Membership;