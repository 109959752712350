import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { motion } from 'framer-motion';
import { FiArrowLeft } from 'react-icons/fi';

const EventPage = () => {
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const { eventId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await fetch(
          'https://0pu9yr4y.api.sanity.io/v2021-08-20/data/query/events',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: `*[_type == "event" && _id == "${eventId}"][0]{
                _id,
                eventName,
                date,
                image {
                  asset->{
                    _id,
                    url
                  }
                },
                ticketLink,
                venue,
                city,
                state,
                description
              }`
            }),
          }
        );
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (data.result) {
          setEvent(data.result);
        } else {
          setError('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        setError('Error fetching event');
      }
    };

    fetchEvent();
  }, [eventId]);

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen text-2xl">Error: {error}</div>
    );
  }

  if (!event) {
    return (
      <div className="flex items-center justify-center min-h-screen text-2xl">Loading...</div>
    );
  }

  const hasImage = event.image && event.image.asset && event.image.asset.url;
  const imageUrl = hasImage ? event.image.asset.url : null;

  const startDate = moment.tz(event.date.startDate, event.date.timeZone);
  const formattedDate = startDate.format('MM/DD'); // Changed from 'DD/MM' to 'MM/DD'
  const formattedTime = startDate.format('hh:mm A'); // Changed from 'HH:mm' to 'hh:mm A' for 12-hour format

  return (
    <motion.div 
      className="w-full min-h-screen flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="p-4 border-b border-black">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center justify-center w-10 h-10 bg-white border border-black hover:bg-gray-100 transition-colors duration-300"
          aria-label="Back to Events"
        >
          <FiArrowLeft size={24} />
        </button>
      </div>
      <div className="flex-grow grid grid-cols-1 md:grid-cols-2 gap-0">
        <div className="relative border border-black overflow-hidden" style={{ maxHeight: 'calc(100vh - 2rem)' }}>
          {hasImage ? (
            <img
              src={imageUrl}
              alt={event.eventName}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center bg-gray-200">
              No Image Available
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between p-4 md:p-8 border-t md:border-t-0 md:border-l border-r border-b border-black overflow-y-auto" style={{ maxHeight: 'calc(100vh - 2rem)' }}>
          <div>
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 uppercase">{event.eventName}</h1>
            <p className="text-lg md:text-xl mb-2">{formattedDate} {formattedTime}</p>
            <p className="text-md md:text-lg mb-2">{event.date.timeZone}</p>
            <p className="text-lg md:text-xl mb-4">{event.venue}, {event.city}, {event.state}</p>
            <div className="mb-8 text-md md:text-lg">{event.description}</div>
          </div>
          <div>
            <button 
              onClick={() => window.open(event.ticketLink, '_blank')}
              className="w-full px-4 md:px-6 py-3 md:py-4 bg-black text-white hover:bg-gray-800 transition-colors duration-300 uppercase text-md md:text-lg font-bold"
            >
              RSVP
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default EventPage;