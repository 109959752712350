import React, { useMemo } from 'react';
import moment from 'moment-timezone';

const EventCard = React.memo(({ event, handleToggleExpand }) => {
  const { formattedDate, formattedTime, timeZone, venue, location } = useMemo(() => {
    const startDate = moment.tz(event.date.startDate, event.date.timeZone || 'UTC');
    return {
      formattedDate: startDate.format('MM/DD'),
      formattedTime: startDate.format('hh:mm A'),
      timeZone: event.date.timeZone,
      venue: event.venue,
      location: `${event.city}, ${event.state}`
    };
  }, [event]);

  return (
    <div 
      className="border border-black p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-300 flex flex-col justify-between h-full"
      onClick={() => handleToggleExpand(event._id)}
    >
      <div>
        <h3 className="font-bold mb-2 uppercase text-xl break-words">{event.eventName}</h3>
        <p className="text-sm mb-1 uppercase font-bold">
          {formattedDate} {formattedTime}
        </p>
        <p className="text-xs mb-1">{timeZone}</p>
        <p className="text-sm mb-1">{venue}</p>
        <p className="text-sm">{location}</p>
      </div>
      <div className="mt-2 self-end">
        <button className="uppercase text-sm font-bold border-b border-black pb-1 hover:border-transparent transition-colors duration-300">
          View Details
        </button>
      </div>
    </div>
  );
});

export default EventCard;