import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import EventCard from './EventCard';
import { analytics, logEvent } from '../firebase';
import { useParams, useNavigate } from 'react-router-dom';

const Locations = () => {
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('upcoming');
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [visibleUpcomingEvents, setVisibleUpcomingEvents] = useState(4);
  const [visiblePastEvents, setVisiblePastEvents] = useState(4);

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Locations',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    const fetchLocationData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          'https://0pu9yr4y.api.sanity.io/v2021-08-20/data/query/events',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              query: `*[_type == 'location']{
                _id,
                locationName,
                events[]-> {
                  _id,
                  eventName,
                  date {
                    startDate,
                    endDate,
                    timeZone
                  },
                  ticketLink,
                  venue,
                  city,
                  state,
                  description
                }
              } | order(locationName asc)`
            }),
          }
        );

        const data = await response.json();
        setLocationData(data.result || []);

        if (eventId) {
          const event = data.result.flatMap(location => location.events).find(event => event._id === eventId);
          if (event) {
            setSelectedEvent(event);
          }
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
        setLocationData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocationData();
  }, [eventId]);

  const filterEvents = useCallback((events) => {
    const now = moment();
    return events
      .filter(event => {
        const eventEnd = event.date.endDate 
          ? moment.tz(event.date.endDate, event.date.timeZone || 'UTC') 
          : moment.tz(event.date.startDate, event.date.timeZone || 'UTC').endOf('day');
        return eventEnd.isAfter(now);
      })
      .sort((a, b) => moment(a.date.startDate).diff(moment(b.date.startDate)));
  }, []);

  const getPastEvents = useCallback((events) => {
    const now = moment();
    return events
      .filter(event => {
        const eventEnd = event.date.endDate 
          ? moment.tz(event.date.endDate, event.date.timeZone || 'UTC') 
          : moment.tz(event.date.startDate, event.date.timeZone || 'UTC').endOf('day');
        return eventEnd.isSameOrBefore(now);
      })
      .sort((a, b) => moment(b.date.startDate).diff(moment(a.date.startDate)));
  }, []);

  const allEvents = useMemo(() => locationData.flatMap(location => location.events), [locationData]);

  const upcomingEvents = useMemo(() => 
    selectedLocation
      ? filterEvents(locationData.find(loc => loc._id === selectedLocation)?.events || [])
      : filterEvents(allEvents),
    [selectedLocation, locationData, filterEvents, allEvents]
  );

  const pastEvents = useMemo(() => 
    selectedLocation
      ? getPastEvents(locationData.find(loc => loc._id === selectedLocation)?.events || [])
      : getPastEvents(allEvents),
    [selectedLocation, locationData, getPastEvents, allEvents]
  );

  const handleLocationChange = useCallback((locationId) => {
    setSelectedLocation(locationId);
    setSelectedEvent(null);
    setIsDropdownOpen(false);
    logEvent(analytics, 'location_click', {
      location_name: locationId ? locationData.find(loc => loc._id === locationId).locationName : 'Upcoming Events'
    });
  }, [locationData]);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen(prev => !prev);
  }, []);

  const handleEventClick = useCallback((eventId) => {
    navigate(`/event/${eventId}`);
  }, [navigate]);

  const renderSkeletonLoader = () => (
    <div className="border border-black p-4 animate-pulse">
      <div className="h-6 bg-gray-300 mb-2 w-3/4"></div>
      <div className="h-4 bg-gray-300 mb-1 w-1/2"></div>
      <div className="h-4 bg-gray-300 mb-1 w-1/3"></div>
      <div className="h-4 bg-gray-300 mb-1 w-2/3"></div>
      <div className="h-4 bg-gray-300 w-1/2"></div>
    </div>
  );

  const renderEvents = (events, title, visibleCount, setVisibleCount) => (
    <div className="mb-12">
      <h2 className="text-2xl font-bold mb-4 px-4">{title}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4">
        {events.slice(0, visibleCount).map((event) => (
          <EventCard
            key={event._id}
            event={event}
            handleToggleExpand={handleEventClick}
          />
        ))}
      </div>
      {events.length > visibleCount && (
        <div className="text-center mt-8">
          <button
            className="bg-black text-white px-4 py-2 uppercase"
            onClick={() => setVisibleCount(prev => prev + 4)}
          >
            Show More
          </button>
        </div>
      )}
    </div>
  );

  return (
    <motion.div 
      className="w-full bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-0 md:px-4 py-8">
        {/* Mobile dropdown */}
        <div className="md:hidden mb-8 px-4">
          <button
            className="w-full p-4 text-lg uppercase bg-black text-white flex justify-between items-center"
            onClick={toggleDropdown}
          >
            <span>{selectedLocation ? locationData.find(loc => loc._id === selectedLocation).locationName : 'All Locations'}</span>
            {isDropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
          </button>
          <AnimatePresence>
            {isDropdownOpen && (
              <motion.div
                className="absolute left-4 right-4 bg-black text-white z-10"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
              >
                {selectedLocation && (
                  <button
                    className="w-full p-4 text-left text-lg uppercase hover:bg-gray-800"
                    onClick={() => handleLocationChange(null)}
                  >
                    All Locations
                  </button>
                )}
                {locationData.map(location => (
                  location._id !== selectedLocation && (
                    <button
                      key={location._id}
                      className="w-full p-4 text-left text-lg uppercase hover:bg-gray-800"
                      onClick={() => handleLocationChange(location._id)}
                    >
                      {location.locationName}
                    </button>
                  )
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Desktop buttons */}
        <div className="hidden md:flex flex-wrap justify-center space-x-8 mb-8">
          <button
            className={`text-lg uppercase mb-2 pb-1 ${!selectedLocation ? 'border-b-2 border-black' : ''}`}
            onClick={() => handleLocationChange(null)}
          >
            All Locations
          </button>
          {locationData.map(location => (
            <button
              key={location._id}
              className={`text-lg uppercase mb-2 pb-1 ${selectedLocation === location._id ? 'border-b-2 border-black' : ''}`}
              onClick={() => handleLocationChange(location._id)}
            >
              {location.locationName}
            </button>
          ))}
        </div>

        {/* Tabs for mobile only */}
        <div className="flex mb-8 md:hidden px-4">
          <button
            className={`flex-1 py-2 text-center ${activeTab === 'upcoming' ? 'bg-black text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('upcoming')}
          >
            Upcoming Events
          </button>
          <button
            className={`flex-1 py-2 text-center ${activeTab === 'past' ? 'bg-black text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('past')}
          >
            Past Events
          </button>
        </div>

        {/* Events */}
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-4">
            {[...Array(4)].map((_, index) => (
              <div key={index}>{renderSkeletonLoader()}</div>
            ))}
          </div>
        ) : (
          <>
            {/* Mobile: Show either upcoming or past events based on activeTab */}
            <div className="md:hidden">
              {activeTab === 'upcoming' 
                ? renderEvents(upcomingEvents, 'UPCOMING EVENTS', visibleUpcomingEvents, setVisibleUpcomingEvents)
                : renderEvents(pastEvents, 'PAST EVENTS', visiblePastEvents, setVisiblePastEvents)
              }
            </div>
            
            {/* Desktop: Show both upcoming and past events */}
            <div className="hidden md:block">
              {renderEvents(upcomingEvents, 'UPCOMING EVENTS', visibleUpcomingEvents, setVisibleUpcomingEvents)}
              {renderEvents(pastEvents, 'PAST EVENTS', visiblePastEvents, setVisiblePastEvents)}
            </div>
          </>
        )}

        {upcomingEvents.length === 0 && pastEvents.length === 0 && !isLoading && (
          <p className="text-xl text-center px-4">No events found. Stay tuned!</p>
        )}
      </div>
    </motion.div>
  );
};

export default Locations;