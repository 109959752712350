import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { functions, httpsCallable, analytics, logEvent } from '../firebase';
import { CartContext } from '../contexts/CartContext';
import Cart from './Cart';
import { motion, AnimatePresence } from 'framer-motion';

// Add this function at the top of your file, outside of the component
const extractNumericId = (gid) => {
  const parts = gid.split('/');
  return parts[parts.length - 1];
};

const ProductPage = () => {
  const { handle } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const { addToCart, isCartOpen, setIsCartOpen } = useContext(CartContext);

  const fetchProduct = useCallback(async () => {
    try {
      const getShopifyProduct = httpsCallable(functions, 'getShopifyProduct');
      const result = await getShopifyProduct({ handle });
      if (result.data && result.data.product) {
        const product = result.data.product;
        setProduct(product);
        
        const firstAvailableVariant = product.variants.find(variant => variant.availableForSale);
        setSelectedVariant(firstAvailableVariant || product.variants[0]);

        logEvent(analytics, 'view_item', {
          currency: product.variants[0].price.currencyCode,
          value: parseFloat(product.variants[0].price.amount),
          items: [{
            item_id: product.id,
            item_name: product.title,
            price: parseFloat(product.variants[0].price.amount)
          }]
        });
      } else {
        setError('Failed to fetch product details');
      }
    } catch (err) {
      console.error('Error fetching product:', err);
      setError('An error occurred while fetching the product');
    } finally {
      setLoading(false);
    }
  }, [handle]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const handleVariantChange = useCallback((variant) => {
    setSelectedVariant(variant);
    logEvent(analytics, 'select_item', {
      items: [{
        item_id: product.id,
        item_name: product.title,
        item_variant: variant.title,
        price: parseFloat(variant.price.amount)
      }]
    });
  }, [product]);

  const handleAddToCart = useCallback(async () => {
    if (selectedVariant && selectedVariant.availableForSale) {
      try {
        await addToCart(product, selectedVariant, 1);
        setIsCartOpen(true);
        logEvent(analytics, 'add_to_cart', {
          currency: selectedVariant.price.currencyCode,
          value: parseFloat(selectedVariant.price.amount),
          items: [{
            item_id: product.id,
            item_name: product.title,
            item_variant: selectedVariant.title,
            price: parseFloat(selectedVariant.price.amount),
            quantity: 1
          }]
        });
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    }
  }, [product, selectedVariant, addToCart, setIsCartOpen]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  if (error) {
    return <div className="flex items-center justify-center min-h-screen text-2xl">Error: {error}</div>;
  }

  if (!product) {
    return <div className="flex items-center justify-center min-h-screen text-2xl">Product not found</div>;
  }

  const isAddToCartDisabled = !selectedVariant || !selectedVariant.availableForSale;

  return (
    <motion.div 
      className="w-full min-h-screen flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex-grow grid grid-cols-1 md:grid-cols-2 gap-0">
        <div className="aspect-w-1 aspect-h-1 border border-black">
          {product.images && product.images.length > 0 && (
            <img
              src={product.images[0].src}
              alt={product.title}
              className="w-full h-full object-contain p-4"
            />
          )}
        </div>
        <div className="flex flex-col justify-between p-8 border-t border-r border-b border-black">
          <div>
            <h1 className="text-3xl mb-4 uppercase">{product.title}</h1>
            <p className="text-2xl mb-4">
              ${selectedVariant.price.amount} {selectedVariant.price.currencyCode}
            </p>
            {selectedVariant && selectedVariant.quantityAvailable !== undefined && selectedVariant.quantityAvailable <= 10 && selectedVariant.quantityAvailable > 0 && (
              <p className="text-red-500 mb-4">Only {selectedVariant.quantityAvailable} left!</p>
            )}
            <div className="mb-4" dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}></div>
            <p className="mb-2">Product ID: {extractNumericId(product.id)}</p>
            <div className="mb-4">
              <p className="mb-2 uppercase">SIZES (US):</p>
              <div className="flex flex-wrap gap-2">
                {product.variants.map(variant => (
                  <button
                    key={variant.id}
                    onClick={() => handleVariantChange(variant)}
                    className={`px-4 py-2 border ${selectedVariant.id === variant.id ? 'bg-black text-white' : 'bg-white text-black'} ${!variant.availableForSale ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!variant.availableForSale}
                  >
                    {variant.title} {variant.availableForSale ? '(In Stock)' : '(Out of Stock)'}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div>
            <button 
              onClick={handleAddToCart}
              className={`w-full px-6 py-3 uppercase transition-colors duration-300 ${
                isAddToCartDisabled
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-black text-white hover:bg-gray-800'
              }`}
              disabled={isAddToCartDisabled}
            >
              {isAddToCartDisabled ? 'Out of Stock' : 'Add to bag'}
            </button>
            <p className="mt-4">
              <a href="#" className="underline">Size Help</a>
            </p>
            <div className="mt-8">
              <p className="uppercase mb-2">SHIPPING & RETURNS</p>
              <p className="uppercase mb-2">PAYMENT METHODS</p>
              <p className="uppercase mb-2">HELP AND CONTACT</p>
            </div>
          </div>
        </div>
      </div>
      <Cart isOpen={isCartOpen} setIsOpen={setIsCartOpen} />
    </motion.div>
  );
};

export default ProductPage;
