import { createContext, useState, useEffect, useCallback } from 'react';
import { functions, httpsCallable } from '../firebase';
import Client from 'shopify-buy';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [client, setClient] = useState(null);

  const initializeShopifyClient = useCallback(async () => {
    try {
      const getStorefrontAccessToken = httpsCallable(functions, 'getStorefrontAccessToken');
      const result = await getStorefrontAccessToken();
      const shopifyClient = Client.buildClient({
        domain: result.data.domain,
        storefrontAccessToken: result.data.token
      });
      setClient(shopifyClient);
    } catch (error) {
      console.error('Failed to initialize Shopify client:', error);
      setError('Failed to initialize. Please try again later.');
    }
  }, []);

  useEffect(() => {
    initializeShopifyClient();
  }, [initializeShopifyClient]);

  const addToCart = useCallback((product, variant, quantity) => {
    setCartItems(prevItems => [
      ...prevItems,
      {
        id: variant.id,
        product: { title: product.title },
        variant: {
          id: variant.id,
          title: variant.title,
          price: variant.price
        },
        quantity: quantity
      }
    ]);
  }, []);

  const removeFromCart = useCallback((variantId) => {
    setCartItems(prevItems => prevItems.filter(item => item.variant.id !== variantId));
  }, []);

  const updateCartItemQuantity = useCallback((variantId, newQuantity) => {
    setCartItems(prevItems => 
      prevItems.map(item => 
        item.variant.id === variantId ? { ...item, quantity: newQuantity } : item
      )
    );
  }, []);

  const initiateCheckout = useCallback(async () => {
    if (!client) {
      throw new Error('Shopify client not initialized');
    }

    setIsLoading(true);
    setError(null);
    try {
      const createCheckoutNew = httpsCallable(functions, 'createCheckoutNew');
      const result = await createCheckoutNew({ items: cartItems });
      
      if (result.data && result.data.webUrl) {
        const checkoutUrl = new URL(result.data.webUrl);
        const customDomainUrl = `https://checkout.blanklocations.com${checkoutUrl.pathname}${checkoutUrl.search}`;
        return customDomainUrl;
      } else {
        throw new Error('Invalid checkout data received');
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [client, cartItems]);

  return (
    <CartContext.Provider value={{
      cartItems,
      addToCart,
      removeFromCart,
      updateCartItemQuantity,
      initiateCheckout,
      isCartOpen,
      setIsCartOpen,
      isLoading,
      error,
      setError
    }}>
      {children}
    </CartContext.Provider>
  );
};
