import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { analytics, logEvent } from '../firebase';
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'About',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  return (
    <motion.div 
      className="w-full bg-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 py-16">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 uppercase">ABOUT _______.</h1>
          
          <div className="mb-12 text-lg md:text-xl uppercase">
            <p className="mb-8">
              BLANK LOCATIONS IS MORE THAN JUST EVENTS IN DIFFERENT CITIES — IT'S THE BACKDROP TO MOMENTS YOU'LL NEVER FORGET. BUT YOU'LL HAVE TO EXPERIENCE IT TO UNDERSTAND.
            </p>
          </div>

          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <Link to="/locations" className="w-full md:w-1/2">
              <button className="w-full text-lg md:text-xl px-6 py-3 bg-black text-white hover:bg-gray-800 transition-colors duration-300 uppercase">
                EXPLORE EVENTS
              </button>
            </Link>
            <Link to="/membership" className="w-full md:w-1/2">
              <button className="w-full text-lg md:text-xl px-6 py-3 bg-black text-white hover:bg-gray-800 transition-colors duration-300 uppercase">
                BECOME A MEMBER
              </button>
            </Link>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
 
