// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCxf_FqE1KUc_4laPxEOxhmCIm5prLYXz8",
    authDomain: "blanklocations-92039.firebaseapp.com",
    projectId: "blanklocations-92039",
    storageBucket: "blanklocations-92039.appspot.com",
    messagingSenderId: "222958761222",
    appId: "1:222958761222:web:dbd23d15f7143284155a87",
    measurementId: "G-VXY6VKFZCN"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app, 'us-central1');
const db = getFirestore(app);

export { analytics, logEvent, httpsCallable, functions, db };
