import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { analytics, logEvent } from '../firebase';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isPartner, setIsPartner] = useState(false);

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Contact',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    logEvent(analytics, 'contact_form_submit');
    console.log('Form submitted:', { name, email, message, isPartner });
    setName('');
    setEmail('');
    setMessage('');
    setIsPartner(false);
  };

  return (
    <div className="container mx-auto px-4 py-16 min-h-screen">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-3xl mx-auto"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-8 uppercase">Contact Us</h1>
        
        <div className="mb-8 text-lg md:text-xl">
          <p className="mb-2">Questions or concerns?</p>
          <p className="mb-2">Fill out the form below</p>
         
        </div>
        
        <form onSubmit={handleFormSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block mb-2 text-lg">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 text-black rounded-md focus:outline-none focus:border-black transition-colors"
              placeholder="Your name"
            />
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 text-lg">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 text-black rounded-md focus:outline-none focus:border-black transition-colors"
              placeholder="Your email"
            />
          </div>
          <div>
            <label htmlFor="message" className="block mb-2 text-lg">Message</label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              rows="4"
              className="w-full p-3 border border-gray-300 text-black rounded-md focus:outline-none focus:border-black transition-colors"
              placeholder="Your message"
            ></textarea>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="isPartner"
              checked={isPartner}
              onChange={(e) => setIsPartner(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="isPartner" className="text-lg">
              I am a restaurateur, venue owner, or event curator
            </label>
          </div>
          <button 
            type="submit" 
            className="w-full text-lg md:text-xl px-6 py-3 bg-black text-white hover:bg-red-600 transition-colors duration-300 uppercase"
          >
            Send Message
          </button>
        </form>

        <div className="mt-12">
          <button
            className="w-full md:w-auto text-lg md:text-xl px-6 py-3 bg-black text-white hover:bg-red-600 transition-colors duration-300 uppercase"
          >
            Become a Partner
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default Contact;