import React from 'react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className="container mx-auto px-4 py-8 text-center">
      <h1 className="text-3xl font-bold mb-4">Thank You for Your Purchase!</h1>
      <p className="mb-4">Your order has been successfully placed.</p>
      <Link to="/" className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800">
        Continue Shopping
      </Link>
    </div>
  );
};

export default ThankYou;
