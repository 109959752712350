import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { analytics, logEvent } from '../firebase';

const FAQ = () => {
  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'FAQ',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      category: 'General',
      questions: [
        {
          question: 'What is Blank Locations?',
          answer:
            'Blank Locations is a social club connecting young professionals through exclusive nightlife experiences and meaningful connections. We offer a curated calendar of events, networking opportunities, and online spaces to foster engagement and build lasting relationships.',
        },
        {
          question: 'Who is Blank Locations for?',
          answer:
            'Blank Locations is designed for young professionals aged 21-35 seeking exceptional experiences and opportunities to connect with like-minded individuals.',
        },
        {
          question: 'How do I become a member?',
          answer:
            "Currently, Blank Locations is operating on a waitlist system. You can sign up on our website to receive updates and be notified when membership becomes available.",
        },
      ],
    },
    {
      category: 'Membership',
      questions: [
        {
          question: 'What are the benefits of being a member?',
          answer:
            "As a member, you'll enjoy several benefits, including:\n\n- Free access to exclusive events\n- Priority RSVPs\n- Member discounts\n- Personalized recommendations\n- Elevated experiences\n- Networking opportunities",
        },
        {
          question: 'How much does membership cost?',
          answer:
            "We haven't finalized the membership fees yet, but we will provide more information on pricing when membership opens.",
        },
      ],
    },
    {
      category: 'Partnerships',
      questions: [
        {
          question: 'How can businesses partner with Blank Locations?',
          answer:
            "We offer a variety of partnership opportunities for businesses to reach our engaged community of young professionals. These include:\n\n- Exclusive offers\n- Event sponsorships\n- Content collaborations\n- Co-branded initiatives",
        },
        {
          question: 'How do I learn more about partnership opportunities?',
          answer:
            'Visit our Partners page or contact us directly to discuss how we can create a mutually beneficial partnership.',
        },
      ],
    },
    {
      category: 'Events',
      questions: [
        {
          question: 'What type of events does Blank Locations host?',
          answer:
            'We offer a diverse range of events including club nights, parties, intimate gatherings, pop-up experiences, and networking events.',
        },
        {
          question: 'How do I find out about upcoming events?',
          answer:
            'Sign up for our email list or follow us on social media to receive updates about upcoming events. You can also browse our website calendar for a complete list of events.',
        },
        {
          question: 'How do I RSVP for an event?',
          answer:
            'You can RSVP for events through our website or mobile app. Members will enjoy priority RSVPs and may have access to exclusive events.',
        },
      ],
    },
  ];

  const handleClick = (index, question) => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex === index ? null : index;
      if (newIndex !== null) {
        logEvent(analytics, 'faq_question_open', {
          question: question
        });
      }
      return newIndex;
    });
  };

  return (
    <div className="container mx-auto p-8 text-white">
      <h1 className="text-4xl font-bold mb-8">Frequently Asked Questions</h1>
      {faqData.map((category, categoryIndex) => (
        <div key={categoryIndex} className="mb-8">
          <h2 className="text-2xl font-bold mb-4">{category.category}</h2>
          {category.questions.map((qa, qaIndex) => (
            <div key={qaIndex} className="mb-4 cursor-pointer">
              <div className="flex items-center" onClick={() => handleClick(qaIndex, qa.question)}>
                <h3 className="text-lg font-semibold">{qa.question}</h3>
                <motion.div
                  className="ml-2"
                  animate={{ rotate: activeIndex === qaIndex ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  &#9660;
                </motion.div>
              </div>
              <AnimatePresence>
                {activeIndex === qaIndex && (
                  <motion.p
                    key={qaIndex}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {qa.answer}
                  </motion.p>
                )}
              </AnimatePresence>
              <div className="border-t border-white mt-2"></div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default FAQ;